import { useGetDeliveries } from '@mindfulchefuk/features/Delivery/hooks/useGetDeliveries'
import { getCustomerId } from '@mindfulchefuk/helpers/js/authentication'
import { useGetCardDetails } from '@mindfulchefuk/hooks/useGetCardDetails'
import { STALE_1_HOUR } from '@mindfulchefuk/constants'

export const useIsNewCustomer = () => {
  const customerId = getCustomerId()
  const isCustomer = !!customerId

  const options = {
    enabled: isCustomer,
    staleTime: STALE_1_HOUR, // We don't want these to be called all the time on every page
  }

  const { isFetched: cardDetailsAreFetched, data: cardDetails } =
    useGetCardDetails(customerId, options)

  const { data: deliveries, isFetched: deliveriesAreFetched } =
    useGetDeliveries(options)

  const isFetched = cardDetailsAreFetched && deliveriesAreFetched

  const hasNoCardDetails = isFetched && !cardDetails

  const isNewCustomer = isFetched && !cardDetails && !deliveries.length

  return {
    hasNoCardDetails,
    isFetched,
    isCustomer,
    isNewCustomer,
  }
}
