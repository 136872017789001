import { DeliveriesDelivery } from '@mindfulchefuk/api-client'
import {
  Delivery,
  DeliveryStatus,
} from '@mindfulchefuk/features/Delivery/interfaces'
import { transformDelivery } from '@mindfulchefuk/features/Delivery/transformers/transformDelivery'

export interface GetDeliveriesParams {
  minDate?: string
  maxDate?: string
  customerId: string
}

export const getDeliveries = async ({
  customerId,
  minDate,
  maxDate,
}: GetDeliveriesParams): Promise<Delivery[]> => {
  const result = await DeliveriesDelivery.select([
    'contents',
    'contents_assigner',
    'delivery_date',
    'delivery_option_id',
    'one_off',
    'portion_count_per_meal',
    'promotional',
    'shipping_price',
    'status',
    'product_contents',
  ])
    .selectExtra(['tracking_link'])
    .order({ delivery_date: 'desc' })
    .extraParams({ pagination_links: false })
    .where({
      status: [
        'billed',
        'billing',
        'payment_pending',
        'billing_failed',
        'billing_timed_out',
        'scheduled',
        'skipped',
      ] as DeliveryStatus[],
      customer_id: { eq: customerId },
      delivery_date: { gte: minDate, lte: maxDate },
    })
    .all()

  return result.data.map(transformDelivery)
}
